<template>
  <div class="mallOrderDetail">
    <div class="success">
      <div class="success-t">
        <template v-if="orderDetail.promoCode">
          <vue-qr :text="orderDetail.promoCode" :size="200"></vue-qr>
        </template>
        <template v-else>
          <img
            v-if="orderDetail.status == 1"
            src="@/assets/img/waitHandle.png"
            alt=""
          />
          <img v-else src="@/assets/img/ivon_success.png" alt="" />
        </template>
      </div>
      <div class="success-b">{{ returnStatus(orderDetail.status) }}</div>
    </div>
    <div class="goods">
      <div class="goods-l">
        <img :src="orderDetail.skuImageUrl" alt="" />
      </div>
      <div class="goods-r">{{ orderDetail.changeTitle }}</div>
    </div>
    <div class="orderInfo">
      <div class="item code" v-if="orderDetail.promoCode">
        <div class="item-l">优惠码：</div>
        <div class="item-r" id="foo">
          {{ orderDetail.promoCode }}
        </div>
        <button class="copy" data-clipboard-target="#foo" @click="copy">
          复制
        </button>
      </div>
      <div class="item">
        <div class="item-l">支付积分：</div>
        <div class="item-r">{{ orderDetail.pointsPrice }}</div>
      </div>
      <div class="item">
        <div class="item-l">兑换数量：</div>
        <div class="item-r">{{ orderDetail.exchangeGoodsNum }}</div>
      </div>
      <div class="item">
        <div class="item-l">兑换时间：</div>
        <div class="item-r">{{ orderDetail.insertTime }}</div>
      </div>
      <div class="item te">
        <div class="item-l">订单号：</div>
        <div class="item-r">{{ orderDetail.orderSn }}</div>
      </div>
      <div class="itemTe">
        <div class="item-l">核销说明：</div>
        <div class="item-r" v-if="orderDetail.promoCode">
          {{ orderDetail.exchangeInstruction }}
        </div>
        <div class="item-r" v-else v-html="orderDetail.exchangeDesc">
          {{ orderDetail.orderSn }}
        </div>
      </div>
    </div>
    <!-- <div class="tipInfo" v-if="orderDetail.status == 1">
      <div class="tipInfo-l">
        <img src="@/assets/img/orderTip.png" alt="" />
      </div>
      <div class="tipInfo-r">温馨提示：此按钮状态由举办方进行确认</div>
    </div>
    <div class="more" @click="showDialog" v-if="orderDetail.status == 1">
      请活动方核销
    </div> -->
    <v-dialog v-model="isDialog" title="提示" @confirm="confirm">
      <div class="tips">
        <div class="tips-t">确定要核销兑换商品吗？</div>
        <div class="tips-b">
          <div class="tips-bl">
            <img src="@/assets/img/notice.png" alt="" />
          </div>
          <div class="tips-br">请活动方人员核销哦</div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import Clipboard from "clipboard";
import { getOrderDetailUrl, handleOrderUrl } from "./api.js";
import { statusOps } from "./map.js";
import { getOption, handleImg } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "mallOrderDetail",
  data() {
    return {
      id: "",
      isDialog: false,
      orderDetail: {},
    };
  },
  components: {
    vueQr,
  },
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getActivityDetail();
  },
  methods: {
    copy() {
      let _this = this;
      var clipboard = new Clipboard(".copy");
      clipboard.on("success", (e) => {
        console.log("复制成功", e);
        _this.$toast({ message: "复制成功", duration: 2000 });
        //  释放内存
        clipboard.destory();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持复制");
        // 释放内存
        clipboard.destory();
      });
    },
    showDialog() {
      this.isDialog = true;
    },
    confirm() {
      this.isDialog = false;
      this.handle();
    },
    returnStatus(status) {
      if (status) {
        return getOption(status, statusOps, "key")["value"];
      } else {
        return "待核销";
      }
    },
    handle() {
      let params = {
        id: this.id,
      };
      this.$axios
        .post(`${handleOrderUrl}`, this.$qs.stringify(params))
        .then((res) => {
          console.log(res, 5555);
          if (res.code === 200) {
            this.$toast({ message: "核销成功", duration: 2000 });
            this.getActivityDetail();
          } else {
          }
        });
    },
    getActivityDetail() {
      let params = {
        id: this.id,
      };
      this.$axios
        .post(`${getOrderDetailUrl}`, this.$qs.stringify(params))
        .then((res) => {
          console.log(res, 5555);
          if (res.code === 200) {
            this.orderDetail = res.data;
            if (this.orderDetail.skuImageUrl) {
              this.orderDetail.skuImageUrl = handleImg(
                375,
                375,
                this.orderDetail.skuImageUrl
              );
            }
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.mallOrderDetail {
  padding: 40px;
  .success {
    .success-t {
      width: 140px;
      height: 140px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .success-b {
      font-size: 36px;
      text-align: center;
      margin-top: 20px;
    }
  }
  .goods {
    display: flex;
    margin: 40px 0;
    align-items: center;
    padding: 40px;
    background: #f7f7f7;
    border-radius: 10px;
    .goods-l {
      width: 120px;
      height: 120px;
      margin-right: 40px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    .goods-r {
      font-size: 30px;
      line-height: 32px;
    }
  }
  .orderInfo {
    margin-top: 100px;
    .item {
      display: flex;
      align-items: center;
      font-size: 32px;
      margin: 20px 0;
      color: #999;
      .item-l {
        width: 180px;
      }
      .copy {
        color: rgba(0, 126, 255, 1);
        background: none;
      }
    }
    .item-r {
      flex: 1;

      word-break: break-all;
    }
    .te {
      align-items: flex-start;
    }
  }
  .itemTe {
    font-size: 32px;
    margin: 20px 0;
    color: #999;
    .item-l {
      width: 180px;
    }
    .item-r {
      margin: 20px 0 0;
    }
  }
  .tipInfo {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 180px;
    .tipInfo-l {
      width: 30px;
      height: 30px;
      font-size: 0;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .tipInfo-r {
      color: #999;
      font-size: 30px;
    }
  }
  .more {
    width: 90%;
    box-sizing: border-box;
    height: 90px;
    border-radius: 10px;
    background: #72b833;
    font-size: 32px;
    line-height: 90px;
    color: #fff;
    text-align: center;
    position: fixed;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .tips {
    .tips-t {
      font-size: 34px;
      text-align: center;
      padding: 0 20px;
      margin: 40px 0;
    }
    .tips-b {
      display: flex;
      margin: 40px 0;
      justify-content: center;
      align-items: center;
      .tips-bl {
        width: 30px;
        height: 30px;
        font-size: 0;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tips-br {
        font-size: 28px;
      }
    }
  }
}
</style>
<style lang="less">
.mallOrderDetail {
  .orderInfo {
    .itemTe {
      .item-r {
        font-size: 32px !important;
        img {
          width: 100%;
        }

        span {
          font-size: 30px !important;
        }
      }
    }
  }
}
</style>
